const BASE_URL = "https://www.starlo.me";
const BASE_URL_HOMEPAGE = "https://www.starlo.me/home";
const BASE_URL_SETTINGS = "https://www.starlo.me/settings";
const BASE_URL_HOMEPAGE_SUCCESS = "https://www.starlo.me/home?gotPremium=true";
const BASE_URL_EMBED = "https://www.starlo.me/embed";

export {
  BASE_URL,
  BASE_URL_HOMEPAGE,
  BASE_URL_SETTINGS,
  BASE_URL_HOMEPAGE_SUCCESS,
  BASE_URL_EMBED,
};
