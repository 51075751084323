import posthog from "posthog-js";
import { useEffect, useState } from "react";

import { PostHogProvider } from "posthog-js/react";
import { useAuth } from "context/AuthContext";
import { getCookieConsentValue } from "react-cookie-consent";
import usePremiumStatus from "hooks/app/usePremiumStatus";

export default function PHProvider({ children }) {
  const { user } = useAuth();
  const userIsPremium = usePremiumStatus(user);
  const [phInstance, setPhInstance] = useState(null);

  useEffect(() => {
    const consent = getCookieConsentValue();
    const initPostHog = (mode) => {
      const ph = posthog.init(
        process.env.NEXT_PUBLIC_POSTHOG_KEY,
        {
          persistence: mode === "memory" ? "memory" : "localStorage+cookie",
          api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
          ...(mode !== "memory" && {
            bootstrap: { distinctID: phInstance?.get_distinct_id() },
          }),
          loaded: (posthog) => {
            if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
          },
        },
        mode
      );
      setPhInstance(ph || null);
    };

    if (consent === undefined) {
      initPostHog("memory");
    } else if (!consent) {
      initPostHog("memory");
    } else {
      initPostHog("cookie");
      posthog.identify(user?.uid ?? "Anon", {
        email: user?.email,
        name: user?.name,
        isCreator: userIsPremium,
      });
    }
  }, [phInstance, user?.email, user?.name, user?.uid, userIsPremium]);

  return <PostHogProvider client={phInstance}>{children}</PostHogProvider>;
}

// export function PostHogPageview(): JSX.Element {
//   const pathname = usePathname();
//   const searchParams = useSearchParams();
//   const { user } = useAuth();
//   const consent = getCookieConsentValue();
//   const [phInstance, setPhInstance] = useState<PostHog | null>(null);
//   const [phInitiatedMemory, setPhInitiatedMemory] = useState<boolean>(false);
//   const [phInitiatedLocalStorage, setPhInitiatedLocalStorage] =
//     useState<boolean>(false);

//   useEffect(() => {
//     if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
//       return;
//     }
//     // do nothing until pages load fully and cookie consent is initiated
//     if (document?.readyState !== "complete") {
//       return;
//     }

//     // user didn't give consent yet. We need to init posthog in memory mode
//     if (consent === undefined) {
//       if (phInstance || phInitiatedMemory) {
//         return;
//       }
//       const ph = posthog.init(
//         process.env.NEXT_PUBLIC_POSTHOG_KEY,
//         {
//           persistence: "memory",
//           api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
//           capture_pageview: false, // Disable automatic pageview capture, as we capture manually
//         },
//         "memory"
//       );
//       if (ph) {
//         ph.register({ consent: "undecided" });
//       }
//       setPhInstance(ph || null);
//       setPhInitiatedMemory(true);
//       return;
//     }

//     // User denied consent. We still run posthog, but in memory mode as initiated above
//     if (!consent) {
//       if (phInstance) {
//         phInstance.register({ consent: "denied" });
//       }
//       return;
//     }
//     // ph is already initiated in localstorage mode
//     if (phInitiatedLocalStorage) {
//       return;
//     }

//     // user gave consent. We need to init posthog in localstorage mode
//     let distinctID = undefined;
//     if (phInstance) {
//       distinctID = phInstance.get_distinct_id();
//       phInstance.unregister("memory");
//     }

//     const ph = posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
//       persistence: "localStorage+cookie",
//       bootstrap: {
//         distinctID,
//       },
//       api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
//       loaded: (posthog) => {
//         if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
//       },
//     });
//     if (ph) {
//       ph.register({ consent: "granted" });
//     }
//     setPhInstance(ph || null);
//     setPhInitiatedLocalStorage(true);
//   }, [consent, phInstance, phInitiatedMemory, phInitiatedLocalStorage]);

//   useEffect(() => {
//     if (!phInstance) {
//       return;
//     }
//     if (!pathname) {
//       return;
//     }
//     let url = window.origin + pathname;
//     if (searchParams && searchParams.toString()) {
//       url = url + `?${searchParams.toString()}`;
//     }
//     phInstance.capture("$pageview", {
//       $current_url: url,
//     });
//   }, [pathname, searchParams, phInstance]);

//   useEffect(() => {
//     if (!phInstance) {
//       return;
//     }
//     if (!user) {
//       return;
//     }
//     phInstance.identify(user.uid, {
//       email: user.email,
//     });
//   }, [user, phInstance]);

//   return <></>;
// }

// export function PHProvider({ children }: { children: React.ReactNode }) {
//   return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
// }
