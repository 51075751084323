import "styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import type { NextWebVitalsMetric } from "next/app";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import CookieConsent from "react-cookie-consent";

import { AuthProvider } from "context/AuthContext";
import ProtectedRoute from "components/common/ProtectedRoute";
import { PROTECTED_ROUTES } from "constants/protectedRoutes";
import SEO from "config/seo";
import PHProvider from "provider/PostHogProvider";

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [faviconHref, setFaviconHref] = useState("/favicon-light.png");

  const getFaviconPath = (isDarkMode = false) => {
    return `/favicon-${isDarkMode ? "dark" : "light"}.png`;
  };

  // Todo move these to hooks
  useEffect(() => {
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");

    setFaviconHref(getFaviconPath(matcher.matches));

    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));

    return () => {
      matcher.onchange = null;
    };
  }, []);

  useEffect(() => {
    const onMouseMove = () => document.body.classList.remove("focus-visible");
    const onKeyDown = ({ key }) =>
      key === "Tab" && document.body.classList.add("focus-visible");

    window.addEventListener("mousemove", () => onMouseMove);

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <>
      <DefaultSeo {...SEO} />
      <PHProvider>
        <AuthProvider>
          <Script id="rewardfulGlobal">{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</Script>
          <Script
            async
            src="https://r.wdfl.co/rw.js"
            data-rewardful="dcdafa"
          ></Script>

          <Head>
            <link rel="icon" href={faviconHref} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no maximum-scale=1.0"
            />
          </Head>

          {PROTECTED_ROUTES.includes(router.pathname) ? (
            <ProtectedRoute>
              <Component {...pageProps} />
            </ProtectedRoute>
          ) : (
            <Component {...pageProps} />
          )}
          <CookieConsent
            acceptOnScrollPercentage={30}
            enableDeclineButton
            buttonText="Accept"
            declineButtonText="Decline"
            buttonStyle={{
              background: "#ea580c",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
            }}
            declineButtonStyle={{
              background: "#9ca3af",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
            }}
            contentStyle={{ flex: "" }}
            acceptOnScroll={true}
            style={{
              background: "#fafafa",
              color: "#0f172a",
              zIndex: 2147483648,
              paddingRight: "10%",
              justifyContent: "start",
            }}
          >
            This website uses{" "}
            <Link href="/privacy" className="cursor-pointer underline">
              cookies
            </Link>{" "}
            to enhance the user experience.
          </CookieConsent>
        </AuthProvider>
      </PHProvider>
    </>
  );
}

export default App;
